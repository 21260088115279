.scrollbar-light::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(116, 2, 2, 0.3);
  background-color: #f5f5f5;
}

.scrollbar-light::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.scrollbar-light::-webkit-scrollbar-thumb {
  background-color: #d92e48;
}

.scrollbar-dark::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(116, 2, 2, 0.3);
  background-color: #f5f5f5;
}

.scrollbar-dark::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.scrollbar-dark::-webkit-scrollbar-thumb {
  background-color: #2c7a7b;
}
