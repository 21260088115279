.response {
  white-space: pre-line;
}

.response ol {
  list-style-position: inside;
}

.response ul {
  list-style-position: inside;
}

.response a {
  font-weight: bold;
  text-decoration: underline;
}
